<template>
  <div>
    <div class="container small margin-bottom-30">
      <div class="logo-image">
        <img
          src="@/assets/img/logo/logo-kvadrat-lilla.svg"
          alt="Aros Forsikring GS"
        />
      </div>
      <h1 class="page-headline margin-bottom-40">
        Din forsikring er opdateret
      </h1>
      <p class="text-align-center font-size-18">
        Tak for din tid! Din forsikring er nu opdateret, og du vil snart modtage
        en bekræftelsesmail. I mailen har vi samlet en række relevante
        informationer om din nye bilforsikring og om os som dit
        forsikringsselskab. Du kan læse mere om Aros Forsikring på vores
        <a href="https://www.aros-forsikring.dk/" target="_blank">hjemmeside</a>.
      </p>
      <br />
      <br />
      <h4 class="text-align-center">Ønsker du at få et tilbud på dine øvrige forsikringer?</h4>
      <div class="button-flex-container justify-center">
        <router-link to="/" tag="button" class="button">Prøv vores prisberegner</router-link>
      </div>
    </div>
    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :altTitle="`Mere om ${product.productName}`"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
    >
      Med vores bilforsikring er din leasede bil forsvarligt dækket ind i en
      travl hverdag, hvor meget kan ske – også uheld og ulykker. Du vælger selv,
      hvilke tilvalgsforsikringer du ønsker at tilføje til din bilforsikring. Er
      du uheldig og får en skade, kan du frit vælge dit lokale værksted, og
      vælger du et af vores fordelsværksteder, giver det mulighed for lånebil på
      attraktive vilkår.
    </product-footer>
  </div>
</template>

<script>
import M90 from "@/assets/json/M90.json";
import productFooter from "@/components/product-footer.vue";

export default {
  name: "BmcLeasingEnd",
  components: {
    productFooter,
  },
  props: {
    token: {
      type: String,
    },
  },
  data() {
    return {
      productId: "M90",
      product: this.copy(M90),
    };
  },
  created() {
    // If token is not there, send to frontpage
    if (!this.token) {
      this.$router.push({
        name: "Hjem",
      });
    }

    this.scrollToTop();
  },
};
</script>
<style lang="scss" scoped>
.logo-image {
  max-width: 100px;
  margin: 0 auto 4rem;
}
</style>
